// @flow
import React, {useState, useEffect} from "react"
import {Row, Col, Card, Button, Modal} from "react-bootstrap"
import moment from "moment"
import {FormInput} from "../../../components/"
import {useForm} from "react-hook-form"
import _ from "lodash"
import Select from "react-select"
import {useNavigate} from "react-router-dom"
import PreviousTicketsTable from "./PreviousTicketsTable"
import ComplaintsInTickets from "./ComplaintsInTickets"
import {Link} from "react-router-dom"
import {IMAGE_UPLOAD_URL} from "../../../utils/apiCaller"
import CalendarFullModal from "../../bookings/components/CalendarFullModal"
import { useLocation } from 'react-router-dom';

const TicketDetailsBox = (props) => {
  let navigate = useNavigate()
  const [signInModal, setSignInModal] = useState(false)
  const [uploadedUrl, setUploadedUrl] = useState(null)
  const [resolutionStatus, setResolutionStatus] = useState([])
  const [showCalendar, setShowCalendar] = useState(false)
  



  const methods = useForm()
  const {
    register,
    control,
    formState: {errors},
    handleSubmit,
  } = methods

  useEffect(() => {})

  const onSubmit = (data) => {
    // its a
    const resolutionImages = [uploadedUrl]
    let dataArray = props.ticketData.dataArray
    if (props.ticketData.dataArray.length > 0) {
      if (data.resolution) {
        dataArray.push({
          _id: dataArray.length + 1,
          type: "text",
          text: data.resolution,
          createdAt: new Date(),
          renderRight: false,
          showDate: true,
        })
      }
      if (uploadedUrl) {
        dataArray.push({
          _id: dataArray.length + 1,
          type: "image",
          url: resolutionImages,
          createdAt: new Date(),
          renderRight: false,
        })
      }
    }
    if (resolutionStatus.value == "CLOSE") {
      if (data.resolution) {
        dataArray.push({
          _id: dataArray.length + 1,
          text: `Hope you are satisfied with the resolution.`,
          type: "resolution",
          createdAt: new Date(),
          renderRight: false,
        })
      }
      if (_.isEmpty(data.resolution)) {
        alert("Please enter resolution")
      } else {
        const resolution = data.resolution
        toggleSignIn()
        props.updateStatus(
          resolutionStatus.value,
          resolution,
          dataArray,
          resolutionImages
        )
      }
    }
    if (resolutionStatus.value == "HOLD") {
      if (_.isEmpty(data.resolution)) {
        alert("Please enter resolution")
      } else {
        const resolution = data.resolution
        toggleSignIn()
        props.updateStatus(
          resolutionStatus.value,
          resolution,
          dataArray,
          resolutionImages
        )
      }
    }
    // else {
    //   toggleSignIn()
    //   props.updateStatus(resolutionStatus.value)
    // }
  }

  const toggleSignIn = () => {
    setSignInModal(!signInModal)
  }

  const setImages = async (e) => {
    if (e.target.files && e.target.files[0]) {
      var formdata = new FormData()
      formdata.append("file", e.target.files[0], e.target.files[0].name)

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      const images = await fetch(IMAGE_UPLOAD_URL, requestOptions)
        .then((response) => response.json().then((json) => ({json, response})))
        .then(({json, response}) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          return json
        })
        .then(
          (response) => response,
          (error) => error
        )

      setUploadedUrl(images.link)
    }
  }
  console.log(props?.bookingData)
  if (props.ticketData?.ticketType == "internal") {
    return (
      <Card style={{}}>
        <Card.Body>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <h4 className="">Ticket Information</h4>
            {(props.ticketData?.status == "HOLD" ||
              props.ticketData?.status == "OPEN") && (
              <button
                className="btn btn-block"
                style={{backgroundColor: "#2A882A", color: "white"}}
                onClick={toggleSignIn}
              >
                Update Ticket Status
              </button>
            )}
          </div>
          <div
            style={{
              borderTop: "5px solid #e6e6e6",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          ></div>
          <p className="">
            <strong>Ticket Id :</strong>{" "}
            <span className="ms-2 bold">
              #
              {props.ticketData?._id
                ?.slice(props.ticketData?._id.length - 8)
                .toUpperCase()}
            </span>
          </p>
          <p className="">
            <strong>Ticket Date :</strong>
            <span className="ms-2">
              {moment(props.ticketData?.date).format("DD-MM-YY | HH:mm")}
            </span>
          </p>
          <p className="">
            <strong>Ticket status :</strong>{" "}
            <span className="ms-2">{props.ticketData?.status}</span>
          </p>
          <p className="">
            <strong>Created By :</strong>{" "}
            <span className="ms-2 text-capitalize">
              {props.ticketData?.assigneeId?.name}
            </span>
          </p>
          <p className="">
            <strong>Assigned To :</strong>{" "}
            <span className="ms-2 text-capitalize">
              {props.ticketData?.assignedToId?.name}
            </span>
          </p>

          <p className="">
            <strong>Issue :</strong>
            <span className="ms-2">{props.ticketData?.message}</span>
          </p>
          <p className="">
            <strong>Resolution :</strong>
            <span className="ms-2">
              {props.ticketData?.commentLogs?.map((comment) => (
                <>
                  <span className="ms-2">{comment?.commentDescription}</span>
                </>
              ))}
            </span>
          </p>
          <div>
            <Modal show={signInModal} onHide={toggleSignIn} size={"lg"}>
              <Col xs={12}>
                <Modal.Body>
                  <form className="ps-3 pe-3" onSubmit={handleSubmit(onSubmit)}>
                    <Row style={{alignItems: "center"}}>
                      <h4 className="text-center mb-4 mt-2">
                        Please provide resolution to close the ticket
                      </h4>
                      <Row>
                        <Select
                          options={[{value: "CLOSE", label: "CLOSE"}]}
                          className="react-select"
                          classNamePrefix="react-select"
                          value={resolutionStatus}
                          onChange={(e) => {
                            // setResolution(e.value)
                            setResolutionStatus(e)
                          }}
                          placeholder="Status"
                        ></Select>
                      </Row>
                      {(resolutionStatus.value === "HOLD" ||
                        resolutionStatus.value === "CLOSE") && (
                        <>
                          <Row className="mt-2">
                            <Col>
                              <FormInput
                                label="Resolution"
                                type="textarea"
                                name="resolution"
                                placeholder="Resolution..."
                                rows="8"
                                containerClass={"mb-3"}
                                register={register}
                                key="resolution"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className="mb-3 text-center mt-4">
                        <button
                          className="btn btn-rounded btn-primary"
                          // type="submit"
                        >
                          Update
                        </button>
                      </div>
                    </Row>
                  </form>
                </Modal.Body>
              </Col>
            </Modal>
          </div>
        </Card.Body>
      </Card>
    )
  } else {
    return (
      <Card style={{}}>
        <Card.Body>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <h4 className="">Ticket Information</h4>
            {(props.ticketData?.status == "HOLD" ||
              props.ticketData?.status == "OPEN") && (
              <button
                className="btn btn-block"
                style={{backgroundColor: "#2A882A", color: "white"}}
                onClick={toggleSignIn}
              >
                Update Ticket Status
              </button>
            )}
          </div>
          <div
            style={{
              borderTop: "5px solid #e6e6e6",
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          ></div>
          <div className="text-start">
            <p className="">
              <strong>Ticket Id :</strong>{" "}
              <span className="ms-2 bold">
                #
                {props.ticketData?._id
                  ?.slice(props.ticketData?._id.length - 8)
                  .toUpperCase()}
              </span>
            </p>
            <p className="">
              <strong>Ticket status :</strong>{" "}
              <span className="ms-2">{props.ticketData?.status}</span>
            </p>
            <p className="">
              <strong>Created By :</strong>{" "}
              <span className="ms-2 text-capitalize">
                {props.ticketData?.assigneeId?.name || "Customer"}
              </span>
            </p>
            <p className="">
              <strong>Assigned To :</strong>{" "}
              <span className="ms-2 text-capitalize">
                {props.ticketData?.assignedToId?.name || ""}
              </span>
            </p>
            {props.bookingData?._id && (
              <Link
                to={{
                  pathname: "/bookings/booking-details",
                  search: `?id=${props.bookingData?._id}`,
                }}
                target="_black"
              >
                <p
                  className="mb-2"
                  // style={{
                  //   cursor: "pointer",
                  // }}
                  // onClick={() =>
                  //   navigate({
                  //     pathname: "/bookings/booking-details",
                  //     search: `?id=${props.bookingData?._id}`,
                  //     target: "_blank",
                  //     replace: false,
                  //   })
                  // }
                >
                  <strong>Booking Id :</strong>{" "}
                  <span className="ms-2">{props.bookingData?.booking_num}</span>
                </p>
              </Link>
            )}

            <Link
              to={`/users/user-details?id=${props.ticketData?.customerId}`}
              target="_blank"
            >
              <p>
                <strong>Customer Name :</strong>{" "}
                <span className="ms-2">{props.ticketData?.name}</span>
              </p>
            </Link>
            <p className="">
              <strong>Mobile :</strong>
              <span className="ms-2">{props.ticketData?.phone}</span>
            </p>
            <p className="">
              <strong>Email :</strong>
              <span className="ms-2">{props.ticketData?.email}</span>
            </p>
            <p className="">
              <strong>Community name :</strong>
              <span className="ms-2">
                {props.bookingData?.addressId?.community_id?.name}
              </span>
            </p>
            <p className="">
              <strong>Apartment :</strong>
              <span className="ms-2">
                {props.bookingData?.addressId?.flat_no}
              </span>
            </p>
            {/* <p className="">
              <strong>Basement :</strong>
              <span className="ms-2">
                {props.bookingData?.addressId?.basement}
              </span>
            </p> */}
            {/* <p className="">
              <strong>Block :</strong>
              <span className="ms-2">
                {props.bookingData?.addressId?.block_id?.name}
              </span>
            </p> */}
            <p className="">
              <strong>Parking Slot :</strong>
              <span className="ms-2">
                {props.bookingData?.addressId?.
parkingDetails
}
              </span>
            </p>
            <div className="d-flex">
              <p className="">
                <strong>Vehicle number :</strong>
                <span className="ms-2">{props.ticketData?.vehicleNo}</span>
              </p>
              {props.bookingData?._id && (
                <span
                  className="ms-1"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowCalendar(true)
                  }}
                >
                  <u
                    className="cm-tw-underline cm-underlined"
                    style={{
                      color: "#727CF5",
                      fontWeight: "bold",
                    }}
                  >
                    Jobs Calendar
                  </u>

                  <i
                    className="mdi mdi-arrow-top-right-thin-circle-outline"
                    style={{color: "#727CF5", marginLeft: "2px"}}
                  ></i>
                </span>
              )}
            </div>
            <Link
              to={`/partners/partner-profile?id=${props.bookingData?.assigned_cleaner_id?._id}`}
              target="_blank"
            >
              <p>
                <strong>Cleaner :</strong>
                <span className="ms-2">
                  {props.bookingData?.assigned_cleaner_id?.name} ( Number :{" "}
                  {props.bookingData?.assigned_cleaner_id?.phone}) (rating:{" "}
                  {props.bookingData?.assigned_cleaner_id?.rating &&
                    Number(
                      props.bookingData?.assigned_cleaner_id?.rating
                    ).toFixed(1)}
                  )
                </span>
              </p>
            </Link>
            <p className="">
              <strong>Booking Type :</strong>
              <span className="ms-2">
                {props.bookingData?.servicepackageId?.service_name}
              </span>
            </p>
            <p className="">
              <strong>Issue type :</strong>
              <span className="ms-2">{props.ticketData?.issueType}</span>
            </p>
            <p className="">
              <strong>Issues selected :</strong>
              {props?.ticketData?.issuesList?.map((issue) => (
                <span className="ms-2">{issue}</span>
              ))}
            </p>
            <p className="">
              <strong>Message :</strong>
              <span className="ms-2">{props.ticketData?.message}</span>
            </p>
            <p className="">
              <strong>Ticket Date :</strong>
              <span className="ms-2">
                {moment(props.ticketData?.date).format("DD-MM-YY | HH:mm")}
              </span>
            </p>

            <Row>
              <p className="">
                <strong>Ticket Images from Customer :</strong>
              </p>
              <div>
                {/* {props.ticketData?.images &&
                  props.ticketData?.images.map((image) => (
                    <div>
                      <a href={image} target="_blank">
                        <img
                          src={image}
                          style={{height: "230px"}}
                          alt=""
                          className="rounded"
                        />
                      </a>
                      <br />
                      <br />
                    </div>
                  ))} */}
                  {props.ticketData?.images && props.ticketData.images.length > 0 ? (
  props.ticketData.images.map((image) => (
    <div key={image}>
      <a href={image} target="_blank" rel="noopener noreferrer">
        <img
          src={image}
          style={{ height: "230px" }}
          alt=""
          className="rounded"
        />
      </a>
      <br />
      <br />
    </div>
  ))
) : (
  "NA"
)}

              </div>
            </Row>
           {props.ticketData?.status == "OPEN" ? null :
            <p className="">
              <strong>Resolution :</strong>
              <span className="ms-2">
                {props.ticketData?.commentLogs?.map((comment) => (
                  <>
                    <span className="ms-2">{comment?.commentDescription}</span>
                  </>
                ))}
              </span>
            </p>}
            {props.ticketData?.status == "OPEN" ? null :
            <Row>
              <p className="">
                <strong>Resolution Images :</strong>
              </p>
              <div>
                {props.ticketData.resolutionImages &&
                  props.ticketData.resolutionImages.map((image) => (
                    <div>
                      <a href={image} target="_blank">
                        <img
                          src={image}
                          style={{height: "230px"}}
                          alt=""
                          className="rounded"
                        />
                      </a>
                      <br />
                      <br />
                    </div>
                  ))}
              </div>
            </Row>}
          </div>
          <ComplaintsInTickets
            bookingData={props.bookingData}
            ticketData={props.ticketData}
            complaintData={props.complaintData}
            getTicketData={props.getTicketData}
          />
          <PreviousTicketsTable
            data={props.previousTickets}
            ticketData={props.ticketData}
            getTicketData={props.getTicketData}
            complaintData={props.complaintData}
          />
          <div>
            <Modal show={signInModal} onHide={toggleSignIn} size={"lg"}>
              <Col xs={12}>
                <Modal.Body>
                  <form className="ps-3 pe-3" onSubmit={handleSubmit(onSubmit)}>
                    <Row style={{alignItems: "center"}}>
                      <h4 className="text-center mb-4 mt-2">
                        Please provide resolution to close the ticket
                      </h4>
                      <Row>
                        <Select
                          // options={[
                          //   {value: "HOLD", label: "HOLD"},
                          //   {value: "CLOSE", label: "CLOSE"},
                          // ]}
                          options={
                            props?.ticketData?.status === "HOLD"
                              ? [{ value: "CLOSE", label: "CLOSE" }]
                              : [
                                  { value: "HOLD", label: "HOLD" },
                                  { value: "CLOSE", label: "CLOSE" },
                                ]
                          }
                          className="react-select"
                          classNamePrefix="react-select"
                          value={resolutionStatus}
                          onChange={(e) => {
                            // setResolution(e.value)
                            setResolutionStatus(e)
                          }}
                          placeholder="Status"
                        ></Select>
                      </Row>
                      {(resolutionStatus.value === "HOLD" ||
                        resolutionStatus.value === "CLOSE") && (
                        <>
                          <Row>
                            <Col>
                              <FormInput
                                label="Resolution"
                                type="textarea"
                                name="resolution"
                                placeholder="Resolution..."
                                rows="20"
                                containerClass={"mb-3"}
                                register={register}
                                key="resolution"
                                errors={errors}
                                control={control}
                              />
                            </Col>
                          </Row>
                          <>
                            {uploadedUrl ? (
                              <Row>
                                <Col>
                                  <a href={uploadedUrl} target="_blank">
                                    <img
                                      src={uploadedUrl}
                                      style={{height: "230px"}}
                                      alt=""
                                      className="rounded"
                                    />
                                  </a>
                                </Col>
                              </Row>
                            ) : (
                              <Row>
                                <Col>
                                  {
                                    <input
                                      class="form-control mb-3 mt-2"
                                      id="formFileSm"
                                      type="file"
                                      onChange={(e) => {
                                        setImages(e)
                                      }}
                                    />
                                  }
                                </Col>
                              </Row>
                            )}
                          </>
                        </>
                      )}
                      <div className="mb-3 text-center mt-4">
                        <button
                          className="btn btn-rounded btn-primary"
                          // type="submit"
                        >
                          Update
                        </button>
                      </div>
                    </Row>
                  </form>
                </Modal.Body>
              </Col>
            </Modal>
          </div>
          {showCalendar && (
            <CalendarFullModal
              setShow={setShowCalendar}
              id={props.bookingData?._id}
            />
          )}
        </Card.Body>
      </Card>
    )
  }
}

export default TicketDetailsBox
