// @flow
import React, {Suspense, useEffect, useReducer, useState} from "react"
import {Row, Col, Card, Tab, Nav, Spinner} from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import {useSearchParams} from "react-router-dom"
import callApi from "../../utils/apiCaller"
import AddressDetailsInBookings from "./components/AddressDetailsInBookings"
import PartnerDetailsInBookings from "./components/PartnerDetailsInBookings"
import UserDetailsInBookings from "./components/UserDetailsInBookings"
import VehicleDetailsInBookings from "./components/VehicleDetailsInBookings"
import BookingDetailsInDailyCleaning from "./components/BookingDetailsInDailyCleaning"
import BookingDetailsInOnDemand from "./components/BookingDetailsInOnDemand"
import ComplaintsAndNotes from "./components/ComplaintsAndNotes"
import BookingSummary from "./components/BookingSummary"
import DailyCleaningCalender from "./components/DailyCleaningCalender"
import moment from "moment"
import swal from "sweetalert"
import HoldHistory from "./components/HoldHistory"
import BookingDetailsRightCard from "./components/cards/BookingDetailsRightCard"
import BookingDetailsLeftCard from "./components/cards/BookingDetailsLeftCard"
import CalendarCard from "./components/CalendarCard"
import AddonsCardForBooking from "./components/cards/AddonsCardForBooking"
import _ from "lodash"
import DoorstepServiceRequestDetails from "../serviceRequests/components/DoorstepServiceRequestDetails"
import TransactionDetails from "./components/TransactionDetails"
import CalendarFull from "./components/CalendarFull"

const BookingDetails = (props) => {
  let navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = React.useState(null)
  const [showFullDetails, setShowFullDetails] = React.useState(false)
  const [showCalender, setShowCalender] = React.useState(false)
  const [calenderData, setCalenderData] = React.useState([])
  const [addons, setAddons] = React.useState([])
  const [show, setShow] = React.useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [tabKey, setTabKey] = useState(
    searchParams.get("tab") || "booking-details"
  )

  useEffect(() => {
    const _id = searchParams.get("id")
    getData(_id || props._id)
    getCalenderData(moment().utc().utcOffset(240).format("YYYY-MM-DD"))
  }, [])

  const getData = (id) => {
    if (id) {
      callApi(`servicebooking/${id}`, "GET").then((res) => {
        if (res.success) {
          let all_data = res.data
          all_data.info = res.info
          setData(all_data)
        }
      })
    }
  }

  const getCalenderData = (date) => {
    let data = {
      servicebookingId: searchParams.get("id"),
      date: date,
    }
    callApi(`servicebooking/getcalaender`, "POST", data)
      .then((res) => {
        if (res.success) {
          let data = res?.data
          data = data.map((item) => {
            item.formatedDate = moment(item.date).utc().utcOffset(240).format("YYYY-MM-DD")
            return item
          })
          setCalenderData(data)
          setIsLoading(false)
        } else {
          swal("Alert", res.message, "alert")
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCalenderView = (status) => {
    setShowCalender(status)
  }

  const updateStatus = (id, status) => {
    callApi(`bookingreq/updatestatus`, "POST", {
      ondemandbookingreqId: id,
      status: status,
    }).then((res) => {
      if (res.success) {
        getData(data?._id)
        swal(
          "Assigned Succusfully",
          "Partner has been assigned successfully",
          "success"
        )
      }
    })
  }

  const handleTabKey = (key) => {
    setTabKey(key)
    const currentSearchParams = new URLSearchParams(window.location.search)
    currentSearchParams.set("tab", key)
    const currentUrlWithoutSearch = window.location.href.split("?")[0]
    window.history.replaceState(
      null,
      null,
      `${currentUrlWithoutSearch}?${currentSearchParams.toString()}`
    )
  }

  return (
    <>
      {isLoading ? (
        <Spinner
          animation="border"
          variant="primary"
          className="d-block mx-auto"
          size="lg"
        />
      ) : (
        <div>
          <div className="mt-3">
            <Row>
              <Col xl={8} lg={7}>
                <BookingDetailsLeftCard
                  data={data}
                  addons={addons}
                  getData={getData}
                  handleShowAvailablePartners={setShow}
                  isFromPaymentPendingBookings={props?.isFromPaymentPendingBookings}
                  isFromCancelledBookings={props?.isFromCancelledBookings}
                />
              </Col>
              <Col xl={4} lg={5}>
                <BookingDetailsRightCard
                  data={data}
                  addons={addons}
                  getData={getData}
                />
              </Col>
            </Row>
          </div>
          {data?.isSubscriptionModel ? (
            <AddonsCardForBooking
              data={data}
              addons={addons}
              getData={getData}
            />
          ) : null}
          <Row className="mt-2">
            <Col xl={12}>
              <Tab.Container defaultActiveKey={tabKey} onSelect={handleTabKey}>
                <Nav variant="tabs" className="nav nav-bordered  mb-3">
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      style={{
                        cursor: "pointer",
                      }}
                      eventKey="booking-details"
                      className="nav-link rounded-0"
                    >
                      Booking Details
                    </Nav.Link>
                  </Nav.Item>
                  {data && data?.isSubscriptionModel && (
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        style={{
                          cursor: "pointer",
                        }}
                        eventKey="hold-bookingdetails"
                        className="nav-link rounded-0"
                      >
                        Hold History
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {/* <Nav.Item className="nav-item">
                <Nav.Link
                  style={{
                    cursor: "pointer",
                  }}
                  eventKey="partner"
                  className="nav-link rounded-0"
                >
                  Partner
                </Nav.Link>
              </Nav.Item> */}
                  {/* <Nav.Item className="nav-item">
                <Nav.Link
                  style={{
                    cursor: "pointer",
                  }}
                  eventKey="user"
                  className="nav-link rounded-0"
                >
                  User
                </Nav.Link>
              </Nav.Item> */}
                  {/* <Nav.Item className="nav-item">
                <Nav.Link
                  style={{
                    cursor: "pointer",
                  }}
                  eventKey="vehicle"
                  className="nav-link rounded-0"
                >
                  Vehicle
                </Nav.Link>
              </Nav.Item> */}
                  {/* <Nav.Item className="nav-item">
                <Nav.Link
                  style={{
                    cursor: "pointer",
                  }}
                  eventKey="address"
                  className="nav-link rounded-0"
                >
                  Address
                </Nav.Link>
              </Nav.Item> */}
                  {data && data?.isSubscriptionModel && (
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        style={{
                          cursor: "pointer",
                        }}
                        eventKey="notes"
                        className="nav-link rounded-0"
                      >
                        Complaints & Notes
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item className="nav-item">
                    <Nav.Link
                      style={{
                        cursor: "pointer",
                      }}
                      eventKey="transactions"
                      className="nav-link rounded-0"
                    >
                      Transactions
                    </Nav.Link>
                  </Nav.Item>
                  {data && data?.isSubscriptionModel && (
                    <Nav.Item className="nav-item">
                      <Nav.Link
                        style={{
                          cursor: "pointer",
                        }}
                        eventKey="calender"
                        className="nav-link rounded-0"
                      >
                        View Calender
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="booking-details">
                    <Col>
                      {data && (
                        <>
                          {data?.isSubscriptionModel ? (
                            <BookingDetailsInDailyCleaning
                              data={data}
                              getData={getData}
                              handleCalenderView={handleCalenderView}
                            />
                          ) : (
                            <BookingDetailsInOnDemand
                              data={data}
                              getData={getData}
                            />
                          )}
                        </>
                      )}
                    </Col>
                  </Tab.Pane>
                  <Tab.Pane eventKey="hold-bookingdetails">
                    {tabKey === "hold-bookingdetails" && data && (
                      <HoldHistory data={data} getData={getData} />
                    )}
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="partner">
                {tabKey === "partner" && data && (
                  <PartnerDetailsInBookings data={data} getData={getData} />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="user">
                {tabKey === "user" && data && (
                  <UserDetailsInBookings data={data} getData={getData} />
                )}
              </Tab.Pane> */}
                  {/* <Tab.Pane eventKey="vehicle">
                {tabKey === "vehicle" && data && (
                  <VehicleDetailsInBookings data={data} getData={getData} />
                )}
              </Tab.Pane> */}
                  {/* <Tab.Pane eventKey="address">
                {tabKey === "address" && data && (
                  <AddressDetailsInBookings
                    data={data}
                    updateData={getData}
                    getData={getData}
                  />
                )}
              </Tab.Pane> */}
                  <Tab.Pane eventKey="notes">
                    {tabKey === "notes" && data && (
                      <ComplaintsAndNotes data={data} getData={getData} />
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="transactions">
                    {data?.customerId?._id ? (
                      <TransactionDetails
                        customerId={data?.customerId?._id}
                        id={searchParams.get("id")}
                        bookingNum={data?.booking_num}
                      />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="calender">
                    {tabKey === "calender" && data && (
                      <Card>
                        <Card.Body>
                          <CalendarFull
                            calenderData={calenderData}
                            getCalenderData={getCalenderData}
                            id={searchParams.get("id")}
                          />
                        </Card.Body>
                      </Card>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
          {showCalender ? (
            <DailyCleaningCalender
              isModalVisible={showCalender}
              setModal={handleCalenderView}
              calenderData={calenderData}
              getCalenderData={getCalenderData}
            />
          ) : null}
          {show && (
            <DoorstepServiceRequestDetails
              show={show}
              setShow={setShow}
              activePartner={data?.assigned_cleaner_id}
              availAblePartner={data?.availAblePartner}
              updateStatus={updateStatus}
              bookingIsClosed={data?.isClosed}
              bookingId={data?._id}
              updateBookingDetails={getData}
              // dataId={dataId}
            />
          )}
        </div>
      )}
    </>
  )
}

export default BookingDetails
