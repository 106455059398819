import fetch from "isomorphic-fetch"
import store from "../index"
import {LogoutUser} from "../redux/appState/appActions"
const PROTOCOL = window.location.protocol
//const HOST = 'localhost:8000';
// const API_URL = `https://test-api.honc.io`
// const API_URL = `https://devapi.honc.io`;
// const API_URL = "http://192.168.0.115:5200"
// const API_URL = "https://devdashboardapi.honc.io"
const API_URL = "https://om.devdashboardapi.honc.io"
// const API_URL = "https://uatadminapi.honc.io"
// const API_URL = "https://adminapi.honc.io"
// const API_URL = "https://betaadminapi.honc.io"
// const API_URL = "https://om-adminapi.honc.io"

export const IMAGE_UPLOAD_URL = "https://om.devapi.honc.io/s3/upload-compressed"
// export const IMAGE_UPLOAD_URL = "https://api.honc.io/s3/upload-compressed"
// export const IMAGE_UPLOAD_URL = "https://om-api.honc.io/s3/upload-compressed"


export default function callApi(endpoint, method = "get", body) {
  let localStorage = window.localStorage
  let user = localStorage.getItem("LOGGED_IN_USER_DETAILS")
    ? JSON.parse(localStorage.getItem("LOGGED_IN_USER_DETAILS"))
    : {}
  let headers = {}
  headers["content-type"] = "application/json"
  if (user && user !== "") {
    headers.Authorization = user.token?.replace(/ /g, "")
  }
  console.log(`${API_URL}/${endpoint}`)
  return fetch(`${API_URL}/${endpoint}`, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  })
    .then((response) => response.json().then((json) => ({json, response})))
    .then(({json, response}) => {
      if (response?.status == 401) {
        store.dispatch(LogoutUser())
      } else if (!response.ok) {
        return Promise.reject(json)
      }
      return json
    })
    .then(
      (response) => response,
      (error) => error
    )
}
